// FireworksEffect.jsx
import React, { useEffect, useRef } from 'react';
import { Fireworks } from 'fireworks-js';
import './FireworksEffect.css';

function FireworksEffect() {
  const containerRef = useRef(null);

  useEffect(() => {
    const fireworks = new Fireworks(containerRef.current, {
      autoresize: true,
      opacity: 0.5,
      acceleration: 1.05,
      friction: 0.95,
      gravity: 1.5,
      particles: 50,
      trace: 3,
      traceSpeed: 10,
      explosion: 5,
      intensity: 30,
      flickering: 50,
      lineStyle: 'round',
      hue: {
        min: 0,
        max: 360
      },
      delay: {
        min: 300,
        max: 400
      },
    
      rocketsPoint: {
        min: 50,
        max: 50
      },
      lineWidth: {
        explosion: {
          min: 1,
          max: 3
        },
        trace: {
          min: 1,
          max: 2
        }
      },
      brightness: {
        min: 50,
        max: 80
      },
      decay: {
        min: 0.015,
        max: 0.03
      },
      mouse: {
        click: false,
        move: false,
        max: 1
      },
      boundaries: {
        x: 50,
        y: 50,
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight
      },
      sound: {
        enable: true,
        files: [
          'explosion0.mp3',
          'explosion1.mp3',
          'explosion2.mp3'
        ],
        volume: {
          min: 4,
          max: 8
        }
      }
    });

    fireworks.start();

    return () => fireworks.stop();
  }, []);

  return <div ref={containerRef} className="fireworks-container" />;
}

export default FireworksEffect;

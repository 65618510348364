import { React, lazy, Suspense } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { CartProvider } from "./Components/CrackersWeb/Shop/CartContext";
import { FavoriteProvider } from "./Components/CrackersWeb/Shop/FavoriteContext";
import { Helmet } from "react-helmet";
import FireworksEffect from "./Components/CrackersWeb/Home/FireworksEffect";

const Header = lazy(() => import("./Components/CrackersWeb/Home/Header"));
const Carousel = lazy(() => import("./Components/CrackersWeb/Home/Carousel"));
const CategoriesGrid = lazy(() =>
  import("./Components/CrackersWeb/Home/CategoriesGrid")
);
const CategoryHeader = lazy(() =>
  import("./Components/CrackersWeb/Home/CategoryHeader")
);
const ContactImage = lazy(() =>
  import("./Components/CrackersWeb/Home/ContactImage")
);
const ShopPage = lazy(() => import("./Components/CrackersWeb/Shop/ShopPage"));
const QuickBuy = lazy(() =>
  import("./Components/CrackersWeb/QuickBuy/QuickBuy")
);
const AdminPage = lazy(() => import("./Components/AdminPage"));
const About = lazy(() => import("./Components/CrackersWeb/About/About"));
const Contact = lazy(() => import("./Components/CrackersWeb/Contact/Contact"));
const CartPage = lazy(() => import("./Components/CrackersWeb/Home/CartPage"));
const DeliveryDetails = lazy(() =>
  import("./Components/CrackersWeb/Home/DeliveryDetails")
);
const ProductDetail = lazy(() =>
  import("./Components/CrackersWeb/Shop/ProductDetail")
);
const ActPopUp = lazy(() => import("./Components/CrackersWeb/Home/ActPopUp"));
const Carousel2 = lazy(() => import("./Components/CrackersWeb/Home/Carousel2"));
const Icons = lazy(() => import("./Components/CrackersWeb/Home/Icons"));
const FavoritesPage = lazy(() =>
  import("./Components/CrackersWeb/Home/FavoritesPage")
);

function App() {
  const location = useLocation();

  return (
    <CartProvider>
      <FavoriteProvider>
        {!location.pathname.startsWith("/fdikmrsdej_admin") && <ActPopUp />}
        <div className="App">
          <Helmet>
            <title>Sivakasi Market</title>
            <meta
              name="description"
              content="Welcome to Sivakasi Market - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta
              name="keywords"
              content="Sivakasi crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Sivakasi crackers shop online, festival fireworks Sivakasi, Sivakasi crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Sivakasi crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Sivakasi crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
            />
            <meta name="Sankarapandi" content="Sivakasi Market" />
            <meta property="og:title" content="Sivakasi Market - Home" />
            <meta
              property="og:description"
              content="Welcome to Sivakasi Market - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta property="og:image" content="/brand-image.jpg" />
            <meta
              property="og:url"
              content="https://www.sivakasimarket.in/"
            />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Sivakasi Market - Home" />
            <meta
              name="twitter:description"
              content="Welcome to Sivakasi Market - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta name="twitter:image" content="/brand-image.jpg" />
          </Helmet>
          <Suspense fallback={<></>}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header /> <FireworksEffect /> <Icons /> <Carousel />{" "}
                    <CategoryHeader /> <CategoriesGrid /> <Carousel2 />{" "}
                    <ContactImage />
                  </>
                }
              />
              <Route
                path="/home"
                element={
                  <>
                    <Header /> <FireworksEffect /> <Icons /> <Carousel />{" "}
                    <CategoryHeader /> <CategoriesGrid /> <Carousel2 />{" "}
                    <ContactImage />
                  </>
                }
              />
              <Route
                path="/shop"
                element={
                  <>
                    <Header /> <ShopPage />
                  </>
                }
              />
              <Route
                path="/quickBuy"
                element={
                  <>
                    <Header />
                    <QuickBuy />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <Header />
                    <About />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Header />
                    <Contact />
                  </>
                }
              />
              <Route
                path="/cart"
                element={
                  <>
                    <Header />
                    <CartPage />
                  </>
                }
              />
              <Route
                path="/delivery-details"
                element={
                  <>
                    <Header />
                    <DeliveryDetails />
                  </>
                }
              />
              <Route
                path="/product/:id"
                element={
                  <>
                    <Header /> <ProductDetail />
                  </>
                }
              />
              <Route
                path="/favorites"
                element={
                  <>
                    <Header /> <FavoritesPage />
                  </>
                }
              />

              <Route path="/fdikmrsdej_admin/*" element={<AdminPage />} />
            </Routes>
          </Suspense>
        </div>
      </FavoriteProvider>
    </CartProvider>
  );
}

export default App;
